import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { first, take } from 'rxjs/operators';
import { AlertService } from 'src/app/services/common/alert.service';
import { LoginService } from 'src/app/services/login.service';
import { UtilityService } from 'src/app/services/utility.service';
import { TermConditionPageComponent } from '../term-condition-page/term-condition-page.component';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DailogComponent } from '../../shared/common/dailog/dailog.component';
import { ResourceService } from 'src/app/services/common/resource.service';
import { IndexedDBService } from 'src/app/services/common/indexedDb.service';
import { StorageKey, StorageService } from 'src/app/services/common/storage.service';
import { UserInfoService } from 'src/app/services/user-info.service';
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    hide: boolean = true;

    hidePassword = true;
    hidePasswordConfirm = true;
    submitted = false;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private alertService: AlertService,
        private formBuilderRegister: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private loginService: LoginService,
        private readonly sso: ScrollStrategyOptions,
        private storageService: StorageService,
        private userInfoService: UserInfoService,
        private resourceService: ResourceService,
        private indexedDbService: IndexedDBService,
    ) {
        this.scrollStrategy = this.sso.noop();
    }
    countryCode: any;
    countryList: any[];
    stateList: any[];
    cityList: any[];
    resource: any = {};

    submittedRegister = true;

    registerForm: FormGroup;
    get fReg() { return this.registerForm.controls; }

    scrollStrategy: ScrollStrategy;

    async ngOnInit(): Promise<void> {
        this.loadResoruce();
        this.resourceService.languageSubject.subscribe(response => {
            this.resource = response;
        });
        this.buildForm();
    }

    loadResoruce() {
        this.resourceService.loadResoruce().subscribe(
            response => {
                this.resource = response;
            });
    }

    buildForm(): void {
        this.registerForm = this.formBuilderRegister.group({
            firstName: ['', [Validators.required]],
            //lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
            password: ['', [
                Validators.required,
                Validators.pattern('^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{6,20}$'),
            ]],
            confirmPassword: ['', [Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z0-9@$!%*?&]{1,10}$')]],
            // country: ['', [Validators.required]],
            //stateId: ['', [Validators.required]],
            //cityId: ['', [Validators.required]],
            //countryCode: ['', [Validators.required]],
            //telephone: ['', [Validators.required, Validators.minLength(10)]],
            termsCondition: [true],
        }, {
            validators: this.passwordMatch.bind(this)
        });
    }

    passwordMatch(formGroup: FormGroup) {
        const password = formGroup.get('password');
        const confirmPassword = formGroup.get('confirmPassword');

        const matchingControl = formGroup.controls['confirmPassword'];

        // return if another validator has already found an error on the matchingControl
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }

        // set error on matchingControl if validation fails
        if (password?.value === confirmPassword?.value) {
            matchingControl.setErrors(null);
        }
        else {
            matchingControl.setErrors({ mustMatch: true });
        }
    }

    togglePasswordVisibility() {
        this.hide = !this.hide;
    }
    toggleConfirmPasswordVisibility(): void {
        this.hidePasswordConfirm = !this.hidePasswordConfirm;
    }

    onSubmitRegister() {
        this.submittedRegister = true;

        if (this.registerForm.invalid)
            return;

        var model = Object.assign({});
        model.userType = ['CUSTOMER'];
        model.firstName = this.fReg.firstName.value;
        //model.lastName = this.fReg.lastName.value;
        model.email = this.fReg.email.value.trim();
        model.password = this.fReg.password.value;
        // model.telephone = this.fReg.telephone.value;
        // model.countryId = this.fReg.country.value;
        // model.stateId = this.fReg.stateId.value;
        // model.cityId = this.fReg.cityId.value;
        model.termsCondition = this.fReg.termsCondition.value;

        this.loginService.register(model).pipe(first()).subscribe({
            next: (response) => {
                if (response.success) {
                    this.submittedRegister = false;
                    this.alertService.showSuccess(response.message);

                    var logininfo: any = {
                        "email": this.fReg.email.value.trim(),
                        "password": this.fReg.password.value,
                    }
                    this.loginService.login(logininfo).subscribe((res: any) => {
                        if (res.success) {
                            let loginResponse = res.data;
                            if (loginResponse.token) {
                                let userData = loginResponse.userInfo;

                                this.storageService.setValue(StorageKey.currentUser, JSON.stringify(userData));
                                this.storageService.setValue(StorageKey.authToken, loginResponse.token);
                                this.userInfoService.setUserInfo(userData);
                                this.userInfoService.setLoginFlag(true);
                                this.userInfoService.setToken(loginResponse.token);
                                this.storageService.loginSubject.next(true);

                                this.activatedRoute.queryParams.subscribe(params => {
                                    let returnUrl = params['returnUrl'];
            
                                    if (returnUrl) {
                                        this.router.navigateByUrl(decodeURIComponent(returnUrl)); // Redirect to the returnUrl
                                    } else {
                                        this.router.navigate(['deal']);
                                    }
                                })
                            }
                            else
                                this.router.navigate(['login'])
                        }
                        else {
                            this.router.navigate(['login'])
                        }
                    });
                } else {
                    this.alertService.showError(response.message);
                }
            },
            error: (error) => {
                this.alertService.showError(error.error.errorMessage);
            },
        });
    }

    onClickTermCondition() {
        let dialogRef = this.dialog.open(DailogComponent, {
            disableClose: true,
            scrollStrategy: this.scrollStrategy,
            width: '70%',
            data:
            {
                title: this.resource.term_conditions,
                component: TermConditionPageComponent
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.fReg.termsCondition.setValue(true);
        });
    }

    navigateToSignin() {
        this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
            let returnUrl = params['returnUrl'] ?? this.router.url;
            if(returnUrl.includes('login') || returnUrl.includes('register')) returnUrl = ''
            if(returnUrl.startsWith('/')) returnUrl = returnUrl.slice(1)
            this.router.navigate(['login'], { queryParams: { returnUrl: returnUrl } });
        })
    }
}