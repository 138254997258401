<div class="profile-authentication-area ptb-30">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3 col-md-12">
        <div class="login-form">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h2>Reset Password</h2>
            </div>
          </div>

          <div *ngIf="successMessage" class="alert alert-success">
            {{ successMessage }}
          </div>

          <form [formGroup]="resetForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="form-group">
              <mat-label>{{ resource.password }}</mat-label>
              <mat-form-field>
                <input matInput [placeholder]="resource.password" formControlName="password" class="passwordheight"
                  [type]="hide ? 'password' : 'text'" maxlength="20">
                <mat-error *ngIf="submitted && f.password.errors?.required" class="invalid-feedback">
                  {{ resource.password }} {{ resource.is_required }}
                </mat-error>
                <mat-error *ngIf="submitted && f.password.errors?.pattern" class="invalid-feedback">
                  {{ resource.password }} {{ resource.passwordInfo }}
                </mat-error>
                <i class="password bx bx-info-circle tooltip-icon"></i>
                <button mat-icon-button matSuffix type="button" class="password_eye_icon"
                  (click)="togglePasswordVisibility($event)" style="background-color: transparent; box-shadow: none;">
                  <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <span class="tooltip-text">
                  <ol><span class="passinfoheading">{{resource.passwordRequirement}}</span>
                    <li>{{resource.minlengthInfo}}</li>
                    <li>{{resource.passwordInfo}}</li>
                  </ol>
                </span>
              </mat-form-field>
            </div>
            <div class="form-group mt-4">
              <label>Confirm Password</label>
              <mat-form-field>
                <!-- Confirm Password Field -->
                <input matInput formControlName="confirmPassword" [placeholder]="resource.password"
                  [type]="hidePasswordConfirm ? 'password' : 'text'" class="password_eye_icon" maxlength="20">
                <button mat-icon-button matSuffix type="button" class="password_eye_icon"
                  (click)="toggleConfirmPasswordVisibility($event)"
                  style="background-color: transparent; box-shadow: none;">
                  <mat-icon>{{ hidePasswordConfirm ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-error *ngIf="submitted && f.confirmPassword.errors?.required" class="invalid-feedback">
                  Confirm Password is required
                </mat-error>
                <mat-error class="invalid-feedback" *ngIf="submitted && f.confirmPassword.errors?.mustMatch">
                  Password and confirm password don't match.
                </mat-error>
              </mat-form-field>
            </div>

            <button type="submit" class="btn" mat-raised-button color="primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>